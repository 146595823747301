import { Conveyance, FileUpload, ImageUpload } from "../entities";

const baseUrl = "https://conveyor.akac.workers.dev/api";

export default class ConveyorClient {
  async getConveyance() {
    let res = await fetch([baseUrl, "/get"].join(""));
    if (!res.ok) throw `Failed to get conveyance: ${res.status} => ${await res.text()}`;

    return (await res.json()) as Conveyance;
  }

  async setConveyance(conveyance: Conveyance) {
    let res = await fetch([baseUrl, "/set"].join(""), {
      method: "POST",
      body: JSON.stringify(conveyance),
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    });
    if (!res.ok) throw `Failed to set conveyance: ${res.status} => ${await res.text()}`;
  }

  async setFile(file: File) {
    let formData = new FormData();
    formData.set("file", file);

    let res = await fetch([baseUrl, "/setFile"].join(""), {
      method: "POST",
      body: formData
    });
    if (!res.ok) throw `Failed to set file conveyance: ${res.status} => ${await res.text()}`;

    return (await res.json()) as FileUpload;
  }

  async setImage(file: File) {
    let formData = new FormData();
    formData.set("image", file);

    let res = await fetch([baseUrl, "/setImage"].join(""), {
      method: "POST",
      body: formData
    });
    if (!res.ok) throw `Failed to set image conveyance: ${res.status} => ${await res.text()}`;

    return (await res.json()) as ImageUpload;
  }
}
