export enum ConveyanceType {
  Url,
  Text,
  File,
  Image
}

export interface Conveyance {
  type: ConveyanceType;
  value: string;
}

export interface FileUpload {
  url: string;
}

export interface ImageUpload {
  id: string;
  title: string;
  url_viewer: string;
  url: string;
  display_url: string;
  width: number;
  height: number;
  size: number;
  time: number;
  expiration: number;
  image: Image;
  thumb: Thumb;
  delete_url: string;
}

export interface Image {
  filename: string;
  name: string;
  mime: string;
  extension: string;
  url: string;
}

export interface Thumb {
  filename: string;
  name: string;
  mime: string;
  extension: string;
  url: string;
}
