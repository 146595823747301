import { ConveyanceType } from "../entities";
import * as Site from "./site";
import * as Dom from "./dom";

declare global {
  interface Window {
    ConveyorClient: unknown;
  }
}

console.clear();

async function main() {
  window.ConveyorClient = Site.conveyorClient;

  let conveyance = await Site.conveyorClient.getConveyance();
  if (conveyance.type == ConveyanceType.Text) {
    let textarea = Site.setTemplate(Dom.templates.textarea) as HTMLTextAreaElement;
    textarea.value = conveyance.value;
  } else {
    let url;

    try {
      url = new URL(conveyance.value);
    } catch (err) {
      alert(`Broken URL: ${err}`);
      return;
    }

    location.replace(url.toString());
  }
}

main();
